<div class="loader" *ngIf="!loaded">
    <div class="loader-spinner"></div>
</div>
<header class="v-header container">
    <div class="fullscreen-video-wrap">
        <video playsinline muted autoplay loop loaded-data (onLoadedData)='load($event)'>
            <source src="/assets/videos/background.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
            <source src="/assets/videos/background.webm" type='video/webm; codecs="vp8, vorbis"'>
            <source src="/assets/videos/background.ogv" type='video/ogg; codecs="theora, vorbis"'>
            Your browser does not support the video element. Kindly update it to latest version.
        </video>
    </div>
    <div class="header-overlay"></div>
    <div class="header-content d-flex flex-column justify-content-center align-items-center">
        <h1>Mission-Ctrl</h1>
        <h4 class="mb-5">We launch your IT</h4>
        <a class="btn btn-primary" href="mailto:info@mission-ctrl.be">Contacteer ons</a>
    </div>
</header>