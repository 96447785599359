import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[loaded-data]',
})
export class LoadedDataDirective {
  @Output() onLoadedData: EventEmitter<boolean> = new EventEmitter<false>();

  @HostListener('loadeddata', ['$event'])
  public onTriggered(event: any): void {
    if (event.target.readyState === 4) {
      try {
        event.target.muted = true;
        event.target.play();
      } catch(err) {
        event.target.controls = true;
      }
      this.onLoadedData.emit(true);
    }
  }
}
