import { Component } from '@angular/core';

@Component({
  selector: 'app-video-background',
  templateUrl: './video-background.component.html',
  styleUrls: ['./video-background.component.scss'],
})
export class VideoBackgroundComponent {
  loaded: boolean = false;

  constructor() {}

  load(loaded) {
    this.loaded = loaded;
  }
}
